import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

//! important only import types
import type {
  RemoteVariableSourceUrl,
  WindowEnvironment,
} from '@s1seven/ng-shared-env';

async function bootstrapApplication() {
  /**
   * instead of creating a constant for the url,
   * you can also use the RemoteVariableSourceUrl type.
   * This has the benefit of not importing @s1seven/ng-shared-env module during build
   **/
  const url: RemoteVariableSourceUrl =
    'https://2xqs5zq3ug.execute-api.eu-central-1.amazonaws.com/Prod/variables';

  window.environment = await fetch(url)
    .then(async (response) => {
      const body = await response.json();
      if (response.status === 200) {
        return body as Promise<WindowEnvironment>;
      }
      throw new Error(body.message ?? 'Failed to fetch environment variables');
    })
    .catch(() => ({}));

  /**
   * @s1seven/ng-shared-env must be imported after setting the window['environment']
   * because the window.environment is used to set some environment properties
   * and the environment is exported as a constant
   */
  const { environment } = await import('@s1seven/ng-shared-env');
  environment.production && enableProdMode();

  /**
   * AppModule must be imported after setting the window variable
   */
  const { AppModule } = await import('./app/app.module');

  return platformBrowserDynamic().bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
  });
}

bootstrapApplication().catch((err) => console.error(err));
